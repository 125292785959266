export default class FAreaJneOridesc {
  constructor(
    id,
    fdivisionBean,

    cityCode,
    cityName,
    districtName,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.fdivisionBean = fdivisionBean;

    this.cityCode = cityCode;
    this.cityName = cityName;
    this.districtName = districtName;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
