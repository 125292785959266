export default class FArea {
  constructor(
    id,
    kode1,
    description,
    alias1,
    alias2,

    fregionBean,
    statusActive=true,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.alias1 = alias1;
    this.alias2 = alias2;

    this.statusActive = statusActive;
    this.fregionBean = fregionBean;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
