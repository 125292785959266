<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabRegionClick">
        PROPINSI
      </v-tab>
      <v-tab class="justify-start" @click="tabAreaClick">
        KABUPATEN
      </v-tab>
      <v-tab class="justify-start" @click="tabSubAreaClick">
        KECAMATAN
      </v-tab>
      <v-tab class="justify-start" @click="tabJntAreaClick" v-if="false">
        J&T
      </v-tab>
      <v-tab class="justify-start" @click="tabJneAreaClick" v-if="false">
        JNE
      </v-tab>
      <v-tab class="justify-start" @click="tabIdExpressAreaClick" v-if="false">
        ID EXPRESS
      </v-tab>
      <v-tab class="justify-start" @click="tabSiCepatAreaClick" v-if="false">
        Si-Cepat
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FRegionTable ref="refTabRegionClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaTable ref="refTabAreaClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FSubAreaTable ref="refTabSubAreaClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaJntTable ref="refTabAreaJntClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaJneTable ref="refTabAreaJneClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaIdExpressDestTable ref="refTabAreaIdExpressClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FAreaSiCepatDestTable ref="refTabAreaSiCepatClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import FRegionTable from "../../components/mitra/region/FRegionTable";
import FAreaTable from "../../components/mitra/region/FAreaTable";
import FSubAreaTable from "../../components/mitra/region/FSubAreaTable";
import FAreaJntTable from "../../components/mitra/region/FAreaJntTable";
import FAreaJneTable from "../../components/mitra/region/FAreaJneOridescTable";
import FAreaIdExpressDestTable from "@/components/mitra/region/FAreaIdExpressDestTable";
import FAreaSiCepatDestTable from "@/components/mitra/region/FAreaSiCepatDestTable";

export default {
  components: {
    FAreaSiCepatDestTable,
    FAreaIdExpressDestTable, FAreaJneTable, FAreaJntTable, FRegionTable,  FAreaTable, FSubAreaTable  },
  data() {
    return {
      firstTabTitle: 'WILAYAH',
      firstTabIcon: 'mdi-map'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabRegionClick(){
      try {
        this.$refs.refTabRegionClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabAreaClick(){
      try {
        this.$refs.refTabAreaClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabSubAreaClick(){
      try {
        this.$refs.refTabSubAreaClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

    tabJntAreaClick() {
      try {
        this.$refs.refTabAreaJntClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabJneAreaClick() {
      try {
        this.$refs.refTabAreaJneClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabIdExpressAreaClick() {
      try {
        this.$refs.refTabAreaIdExpressClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabSiCepatAreaClick(){
      try {
        this.$refs.refTabAreaSiCepatClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    }

  }
}
</script>

<style scoped>
</style>