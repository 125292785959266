import axios from 'axios';
import authHeader from "../auth-header";
import ConstApiUrls from "../const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FAreaJneOridescService {
    getApiUrl() {
        return API_URL
    }

    getAllFAreaJneOridesc(){
        return axios.get(API_URL + `getAllFAreaJneOridesc`, { headers: authHeader() });
    }
    getAllFAreaJneOridescContaining(page, pageSize, sortBy, order, cityCode, cityName, districtName){
        return axios.get(API_URL + `getAllFAreaJneOridescContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&cityCode=${cityCode}&cityName=${cityName}&districtName=${districtName}`, { headers: authHeader() });
    }
    getAllFAreaJneOridescSiteCode(page, pageSize, sortBy, order, sendSiteCode, destinationCode, receiverArea){
        return axios.get(API_URL + `getAllFAreaJneOridescKecamatan?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&sendSiteCode=${sendSiteCode}&destinationCode=${destinationCode}&receiverArea=${receiverArea}`, { headers: authHeader() });
    }
    getFAreaJneOridescById(id){
        return axios.get(API_URL + `getFAreaJneOridescById/${id}`, { headers: authHeader() });
    }
    updateFAreaJneOridesc(item){
        return axios.put(API_URL + `updateFAreaJneOridesc/${item.id}`, item, {headers: authHeader()})
    }
    createFAreaJneOridesc(item){
        return axios.post(API_URL + `createFAreaJneOridesc`, item, {headers: authHeader()})
    }
    createFAreaJneOridescMultiple(items){
        return axios.post(API_URL + `createFAreaJneOridescMultiple`, items, {headers: authHeader()})
    }
    deleteFAreaJneOridesc(id){
        return axios.delete(API_URL + `deleteFAreaJneOridesc/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFAreaJneOridesc(itemIds){
        return axios.delete(API_URL + `deleteAllFAreaJneOridesc`, {
            headers:  authHeader(),
            data: itemIds
        });

    }
    

}
export default new FAreaJneOridescService()