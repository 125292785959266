<template>
  <v-dialog
      v-model="dialogShow"
      :max-width="myConfirmOptions.width"
      :style="{ zIndex: myConfirmOptions.zIndex }"
      @keydown.esc="dialogShow=false"
  >
    <v-card>
      <v-toolbar dark :color="myConfirmOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ myConfirmOptions.title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-flex class="justify-center subtitle-2 font-weight-bold mt-2 red--text">
          {{myConfirmOptions.message1}}<br>{{myConfirmOptions.message2}}
        </v-flex>
        <v-flex class="mt-6">
          <v-autocomplete
              v-model="fdivisionBeanSelected"
              :items="itemsFDivision"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="to Division"
              outlined
              persistent-hint
          ></v-autocomplete>
        </v-flex>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" outlined text @click="dialogShow=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :disabled="fdivisionBeanSelected==undefined " outlined text @click="okeConfirmed" >OKE<v-icon small color="blue">mdi-play</v-icon></v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "CloseDialog",
  data() {
    return {
      dialogShow: false,
      myConfirmOptions: {
        title: 'Konfirmasi',
        message1: 'Lanjutkan Proses?',
        message2: '',
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },

      fdivisionBeanSelected: '',
      itemsFDivision: []
    }
  },

  props: [
  ],
  computed: {
  },
  methods: {
    okeConfirmed: function(){
      // this.myConfirmDelete = false
      this.$emit('eventFromConfirmDialog', this.fdivisionBeanSelected)
    },
    showDialog(message1, message2, itemsFDivision) {
      this.fdivisionBeanSelected = undefined
      // console.log("from parent " + message)
      this.myConfirmOptions.message1 = message1!==undefined?message1:''
      this.myConfirmOptions.message2 = message2!==undefined?message2:''
      this.itemsFDivision = itemsFDivision
      this.dialogShow = !this.dialogShow
    },
    setDialogState(value){
      // console.log(value)
      this.dialogShow =value
    }


  }

}
</script>

<style scoped>

</style>