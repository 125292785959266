import axios from 'axios';
import authHeader from "../auth-header";
import ConstApiUrls from "../const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FAreaSiCepatDestService {
    getApiUrl() {
        return API_URL
    }
    getAllFAreaSiCepatDest(){
        return axios.get(API_URL + `getAllFAreaSiCepatDest`, { headers: authHeader() });
    }
    getAllFAreaSiCepatDestContaining(page, pageSize, sortBy, order, stateName, cityName, districtCode, districtName){
        return axios.get(API_URL + `getAllFAreaSiCepatDestContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&stateName=${stateName}&cityName=${cityName}&districtCode=${districtCode}&districtName=${districtName}`, { headers: authHeader() });
    }
    getFAreaSiCepatDestById(id){
        return axios.get(API_URL + `getFAreaSiCepatDestById/${id}`, { headers: authHeader() });
    }
    updateFAreaSiCepatDest(item){
        return axios.put(API_URL + `updateFAreaSiCepatDest/${item.id}`, item, {headers: authHeader()})
    }
    createFAreaSiCepatDest(item){
        return axios.post(API_URL + `createFAreaSiCepatDest`, item, {headers: authHeader()})
    }
    createFAreaSiCepatDestMultiple(items){
        return axios.post(API_URL + `createFAreaSiCepatDestMultiple`, items, {headers: authHeader()})
    }
    deleteFAreaSiCepatDest(id){
        return axios.delete(API_URL + `deleteFAreaSiCepatDest/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFAreaSiCepatDest(itemIds){
        return axios.delete(API_URL + `deleteAllFAreaSiCepat`, {
            headers:  authHeader(),
            data: itemIds
        });

    }

}
export default new FAreaSiCepatDestService()