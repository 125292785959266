
const ESchemaExpedisiArea = Object.freeze([
    {
        column: 'Nomor',
        type: Number,
        value: item => item.id
    },
    {
        column: 'StateCode',
        type: String,
        value: item => item.stateCode
    },
    {
        column: 'StateName',
        type: String,
        value: item => item.stateName
    },
    {
        column: 'CityCode',
        type: String,
        value: item => item.cityCode
    },
    {
        column: 'CityName',
        type: String,
        value: item => item.cityName
    },
    {
        column: 'DistrictCode',
        type: String,
        value: item => item.districtCode
    },
    {
        column: 'DiscrictName',
        type: String,
        value: item => item.districtName
    },


])


export default ESchemaExpedisiArea