export default class FAreaSiCepatDest {
  constructor(
    id=0,
    fdivisionBean=0,

    stateName="",
    cityName="",
    districtCode="",
    districtName="",

    created,
    modified,
    modifiedBy

  ) {
    this.id = id;
    this.fdivisionBean = fdivisionBean;

    this.stateName = stateName;
    this.cityName = cityName;
    this.districtCode = districtCode;
    this.districtName = districtName;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
