<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>

    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="table"
      transition="scale-transition"
    >
    </v-skeleton-loader>
    <v-data-table
      v-if="! loading"
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="fAreaJneOridescsFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="exportToExcel"
                >
                  <v-icon color="green">mdi-file-excel</v-icon>
                  Export To Excel
                </v-btn>
              </v-list-item>
            </v-list>

          </v-menu>

          <v-btn
              fab
              dark
              color="red"
              x-small
              @click="showConfirmDialog"
              :disabled="multiSelect"
              class="mr-2 ml-2"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>



      <template v-slot:[`item.namaKec`]="{ item }">
        <div class="fill-height">
          <div class="subtitle-2">
            {{item.namaKec}}
          </div>
          <div class="text-caption">
            <span>
              {{item.destAreaCode}}
            </span>
            <span class="font-weight-bold">
              - {{item.receiverArea}}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.namaKab`]="{ item }">
        <div class="fill-height">
          <div class="subtitle-2">
            {{item.namaKab}}
          </div>
          <div class="text-caption">
            <span>
              {{item.sendSiteCode}}
            </span>
            <span class="font-weight-bold">
              - {{item.destinationCode}}
            </span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>


    <ConfirmDialogWithFDivision
        ref="refConfirmDialogWithFDivision"
        @eventFromConfirmDialog="eventFromConfirmDialog"
    ></ConfirmDialogWithFDivision>

    <FAreaJneOriDescDialog :formMode.sync="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FAreaJneOriDescDialog>



    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FAreaJneOridescService from '../../../services/api_ext/f-area-jne-oridesc-service';
import FDivisionService from "../../../services/apiservices/f-division-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";
import ConfirmDialogWithFDivision from "@/components/utils/ConfirmDialogWithFDivision";

import FAreaJneOriDescDialog from "./FAreaJneOridescDialog";
import FormMode from "../../../models/form-mode";
import FAreaJneOridesc from '../../../models/ext/f-area-jne-oridesc'
import FileService from "../../../services/apiservices/file-service";
import FJneService from "@/services/api_ext/f-jne-service";
import ESchemaExpedisiArea from "@/models/e-schema-expedisi-area";

import writeXlsxFile from "write-excel-file";
import FileSaver from 'file-saver'

export default {
  components: { FAreaJneOriDescDialog, DeleteConfirmDialog, ConfirmDialogWithFDivision},
  data () {
    return {
      loading: false,
      title: 'JNE',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1000, 1500],

      search: '',
      headers: [
        { text: 'districtName (Kec)', value: 'districtName', width:"30%" },
        { text: 'Kode Kec', value: 'cityCode', width:"15%" },
        { text: 'cityName', value: 'cityName', width:"30%" },
        { text: 'fdivisionBean', value: 'fdivisionBean', width:"10%" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fAreaJneOridescs:[
        new FAreaJneOridesc(0, '','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFSalesman:[],

      fareaReports:[]

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFAreaJneOridesc()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFAreaJneOridesc()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fAreaJneOridescsFiltered(){
      return this.fAreaJneOridescs
    }
  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFAreaJneOridesc()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },
    fetchFAreaJneOridesc() {

      FAreaJneOridescService.getAllFAreaJneOridescContaining(this.currentPage, this.pageSize, "id", "DESC", this.search, this.search, this.search).then(
          response => {
            const { items, totalPages} = response.data
            this.fAreaJneOridescs = items
            this.totalPaginationPages = totalPages

            console.log(`TotalPage ${totalPages} and TotalItems ${items.lenght} `)

            this.loading = false
          },
          error => {
            console.log(error.response)
            this.loading = false
            if (error.response.status === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )


    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },


    showConfirmDialog(){
      this.$refs.refConfirmDialogWithFDivision.showDialog("Yakin refresh data Destination Code dari API JNE?", "", this.itemsFDivision)
    },
    eventFromConfirmDialog(value){

      this.$refs.refConfirmDialogWithFDivision.setDialogState(false)

      if (value ==undefined) return;
      if (value ==0) return;

      this.loading = true
      FJneService.fetchFromJneDestCodeApiAndSave(value, true).then(
          ()=>{

            // console.log(JSON.stringify(response))
            this.fetchFAreaJneOridesc()
          },
          error =>{
            this.loading = false
            console.log(error)
          }
      )


    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fAreaJneOridescs.push(this.itemSelected)
      // this.fAreaJneOridescs = [itemFromRest].concat(this.fAreaJneOridescs)
      // this.fAreaJneOridescs.unshift(itemFromRest)
      this.fetchFAreaJneOridesc()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fAreaJneOridescsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fAreaJneOridescs[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
            this.fetchFAreaJneOridesc()
          e.toString()
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fAreaJneOridescsFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fAreaJneOridescs[this.itemSelectedIndex]
      FAreaJneOridescService.deleteFAreaJneOridesc(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fAreaJneOridescs.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FAreaJneOridescService.deleteAllFAreaJneOridesc(itemIds).then(
            response => {
              console.log(response)

              if (this.showFilter){
                this.runExtendedFilter()
              }else {
                this.fetchFAreaJneOridesc()
              }
              this.selectedItems = []
              this.closeDialog()
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FAreaJneOridescService.deleteAllFAreaJneOridescs()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FAreaJneOridesc())
        this.itemSelectedIndex = -1
      })
    },

    exportToExcel(){

      let schema = ESchemaExpedisiArea
      let fileBits = 'file_area_jne.xlsx'

      this.fareaReports = []
      FAreaJneOridescService.getAllFAreaJneOridesc().then(
          response =>{

            // console.log(JSON.stringify(response.data))

            response.data.forEach( item => {
              let newItem = item
              newItem.districtCode = item.cityCode
              newItem.cityCode = ""
              this.fareaReports.push(newItem)
            })

            writeXlsxFile(this.fareaReports, {
              schema,
              fileName: fileBits
            })

            let file = new File(fileBits, "file_area.txt", {type: "xlsx/plain;charset=utf-8"});
            FileSaver.saveAs(file);

          }
      )

    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFAreaJneOridesc()
      this.fetchParent()

    }
  }

}
</script>

<style scoped>

</style>