export default class FAreaJnt {
  constructor(
    id,
    fdivisionBean,

    idKec,
    namaKec,

    idKab,
    namaKab,

    idProv,
    namaProv,

    sendSiteCode,
    destinationCode,
    destAreaCode,
    receiverArea,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.fdivisionBean = fdivisionBean;

    this.idKec = idKec;
    this.namaKec = namaKec;
    this.idKab = idKab;
    this.namaKab = namaKab;
    this.idProv = idProv;
    this.namaProv = namaProv;

    this.sendSiteCode = sendSiteCode;
    this.destinationCode = destinationCode;
    this.destAreaCode = destAreaCode;
    this.receiverArea = receiverArea;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
