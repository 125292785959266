<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fAreasFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top  v-if="false">
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="exportToExcel"
                >
                  <v-icon color="green">mdi-file-excel</v-icon>
                  Export ke Excel
                </v-btn>
              </v-list-item>
            </v-list>

          </v-menu>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
              class="ml-2"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            x-small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>


      <template v-slot:[`item.description`]="{ item }">
          {{item.description}}
      </template>
      <template v-slot:[`item.alias1`]="{ item }">
        <div class="grey--text">
          {{item.alias1}}
        </div>
        <div class="grey--text">
          {{item.alias2}}
        </div>
      </template>

      <template v-slot:[`item.fregionBean`]="{ item }">
        <span class="caption">
          {{ lookupFRegion(item.fregionBean) }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FAreaDialog :formMode.sync="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FAreaDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FAreaService from '../../../services/apiservices/f-area-service';
import FRegionService from "../../../services/apiservices/f-region-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FAreaDialog from "./FAreaDialog";
import FormMode from "../../../models/form-mode";
import FArea from '../../../models/f-area'
import FileService from "../../../services/apiservices/file-service";

import writeXlsxFile from 'write-excel-file'
import ESchemaBasic1 from "@/models/e-schema-basic1";
import FileSaver from 'file-saver'

export default {
  components: { FAreaDialog, DeleteConfirmDialog},
  data () {
    return {
      title: 'KABUPATEN',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 25,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'Kode',
          align: 'center',
          sortable: false,
          value: 'kode1',
        },
        { text: 'Kabupaten', value: 'description', width:"25%" },
        { text: 'Alias 1&2', value: 'alias1', width:"20%" },
        { text: 'Propinsi', value: 'fregionBean', width: "20%" },
        { text: 'Aktif', value: 'statusActive' },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fAreas:[
        new FArea(0, '','')
      ],
      itemsFRegion: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFSalesman:[],

      fareaReports:[]

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFArea()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFArea()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fAreasFiltered(){
      return this.fAreas
    }
  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFArea()
      }
    },
    fetchParent(){
      FRegionService.getAllFRegion().then(
          response => {
            this.itemsFRegion = response.data
          },
          error => {
            console.log(error.response)
          }
      )

    },
    fetchFArea() {

      FAreaService.getAllFAreaContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fAreas = items
            this.totalPaginationPages = totalPages
            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFRegion)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fAreas.push(this.itemSelected)
      // this.fAreas = [itemFromRest].concat(this.fAreas)
      // this.fAreas.unshift(itemFromRest)
      this.fetchFArea()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fAreasFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFRegion)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fAreas[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
            this.fetchFArea()
          e.toString()
        }
        this.closeDialog()
    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fAreasFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fAreas[this.itemSelectedIndex]
      FAreaService.deleteFArea(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fAreas.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FAreaService.deleteAllFArea(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fAreasFiltered.indexOf(items[i])
                      this.fAreas.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    console.log("Undeleted Items: " + response.data + " = " + items[i].id)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FAreaService.deleteAllFAreas()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FArea())
        this.itemSelectedIndex = -1
      })
    },

    exportToExcel(){

      let schema = ESchemaBasic1
      let fileBits = 'file_kabupaten.xlsx'

      this.fareaReports = []
      FAreaService.getAllFArea().then(
          response =>{
            // console.log(JSON.stringify(response.data))
            response.data.forEach( item => {
              let newItem = item

              this.fareaReports.push(newItem)
            })

            writeXlsxFile(this.fareaReports, {
              schema,
              fileName: fileBits
            })

            let file = new File(fileBits, "file_area.txt", {type: "xlsx/plain;charset=utf-8"});
            FileSaver.saveAs(file);

          }
      )

    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFRegion (fregionBean) {
      const str = this.itemsFRegion.filter(x => x.id===fregionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFArea()
      this.fetchParent()

    }
  }

}
</script>

<style scoped>

</style>