<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeDialog"
        transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
            class="grey lighten-3"
            elevation="0"
            dense
        >

          <v-toolbar-title>Read File Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">

          <v-row justify="center" align="center">
            <v-col cols="7">
              <v-file-input
                  v-model="currentFile"
                  show-size
                  counter
                  label="Pilih File Excel"
                  accept=".xls,.xlsx"
                  @change="selectFile"
              ></v-file-input>
            </v-col>
            <v-col cols="3" class="pl-2">
              <v-autocomplete
                  v-model="fdivisionBeanSelected"
                  :rules="rulesNotEmtpy"
                  :items="itemsFDivision"
                  item-value="id"
                  item-text="description"
                  auto-select-first
                  dense
                  chips
                  deletable-chips
                  color="blue-grey lighten-2"
                  label="Division"
                  hint="Division"
                  persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pl-2">
              <v-btn color="success" small @click="startUpload" :disabled="!currentFile || fdivisionBeanSelected==='' || sudahUpload===true">
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>

          </v-row>

          <v-row >
            <v-col cols="12" sm="12" md="12">

              <v-skeleton-loader
                  v-if="loading"
                  class="mx-auto"
                  type="table"
                  transition="scale-transition"
              >
              </v-skeleton-loader>

              <v-data-table
                  v-if="!loading"
                  :headers="headers"
                  :items="itemsFAreaIdExpressDestComputed"
                  item-key="name"
                  class="elevation-1"
                  height="400"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
              >
                <template v-slot:[`item.number`]="{ item }" >
                  <span><v-icon small color="green" v-if="item.statusUpload===1">mdi-check-circle</v-icon></span>
                  <span><v-icon small color="red" v-if="item.statusUpload===2">mdi-close</v-icon></span>
                  {{ item.number }}
                </template>

              </v-data-table>

            </v-col>

          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                  v-model="progress"
                  color="light-blue"
                  height="25"
                  reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
            <v-img class="ma-2" :src="previewImage" alt="" max-width="300px" max-height="300px"></v-img>
          </v-row>

        </v-card-text>

      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>


    </v-dialog>

    <v-dialog
        v-model="dialogProgres"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please Wait...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import FAreaIdExpressDest from "@/models/ext/f-area-id-express-dest";
import FAreaIdExpressDestService from "../../../services/api_ext/f-area-id-express-dest-service"


export default {
  name: "ImportExcel",
  props:{
    parentId: Number,
    itemsFDivision: []
  },
  components:{
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data() {
    return {
      loading: false,
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: '',
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      progress: 0,
      message: "",
      description: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'stateName', value: 'stateName' },
        { text: 'cityCode', value: 'cityCode' },
        { text: 'cityName', value: 'cityName' },
        { text: 'districtCode', value: 'districtCode' },
        { text: 'districtName', value: 'districtName' },
      ],
      itemsFAreaIdExpressDest:[],
    };
  },
  computed: {
    itemsFAreaIdExpressDestComputed(){
      return this.itemsFAreaIdExpressDest
    }
  },

  methods: {

    selectFile(file) {

      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe =0
      readXlsxFile(this.currentFile).then( (rows) =>{
        rows.forEach( item => {
          if (rowKe >0){
            // console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let fFAreaIdExpressDestBean = new FAreaIdExpressDest()
            fFAreaIdExpressDestBean.number = rowKe+1

            // if (item[1].toUpperCase() ==="FISIK" || item[1].toUpperCase() ==="FSK" ) {
            //   fFAreaIdExpressDestBean.jenisPekerjaan = "FSK"
            // }else {
            //   fFAreaIdExpressDestBean.jenisPekerjaan = "NONFSK"
            // }
            fFAreaIdExpressDestBean.statusUpload =0
            fFAreaIdExpressDestBean.stateCode = item[0]!==null?item[0]:''
            fFAreaIdExpressDestBean.stateName = item[1]!==null?item[1]:''
            fFAreaIdExpressDestBean.cityCode = item[2]!==null?item[2]:''
            fFAreaIdExpressDestBean.cityName = item[3]!==null?item[3]:''
            fFAreaIdExpressDestBean.districtCode = item[4]!==null?item[4]:''
            fFAreaIdExpressDestBean.districtName = item[5]!==null?item[5]:''

            this.itemsFAreaIdExpressDest.push(fFAreaIdExpressDestBean)
          }

          rowKe++
        })
      })

    },

    startUpload() {
      this.loading = true
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      if (this.fdivisionBeanSelected ===undefined || this.fdivisionBeanSelected==='') {
        this.message = "Please select an Division!";
        return;
      }

      let items = []
      this.itemsFAreaIdExpressDest.forEach( item =>{
        let newItem = item
        newItem.id = 0
        newItem.fdivisionBean = this.fdivisionBeanSelected
        items.push(item)
      })

      this.sudahUpload = true
      FAreaIdExpressDestService.createFAreaIdExpressDestMultiple(this.itemsFAreaIdExpressDest).then(
          response =>{
            console.log(response.data.length)

            this.itemsFAreaIdExpressDest = []

            response.data.forEach( itemResponse => {
              const newItem = Object.assign({}, itemResponse)
              try {
                this.$nextTick(() => {
                  newItem.statusUpload = 1
                  this.itemsFAreaIdExpressDest.push(newItem)
                })
              }catch (e) {
                newItem.statusUpload = 2
                this.itemsFAreaIdExpressDest.push(newItem)
                console.log(e)
              }

            })

            this.loading =false

          },
          error =>{
            this.loading = false
            console.log(error.data)
          }
      )
      

    },

    showDialog(){
      this.dialogShow = true
      this.currentFile = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

      this.sudahUpload = false

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }
      if (this.sudahUpload) {
        this.$emit('importExcelCompleted', "OKE")
      }

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },


  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
