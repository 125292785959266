import axios from 'axios';
import authHeader from "../auth-header";
import ConstApiUrls from "../const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FAreaIdExpressDestService {
    getApiUrl() {
        return API_URL
    }

    getAllFAreaIdExpressDest(){
        return axios.get(API_URL + `getAllFAreaIdExpressDest`, { headers: authHeader() });
    }
    getAllFAreaIdExpressDestContaining(page, pageSize, sortBy, order, cityCode, cityName, districtCode, districtName){
        return axios.get(API_URL + `getAllFAreaIdExpressDestContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&cityCode=${cityCode}&cityName=${cityName}&districtCode=${districtCode}&districtName=${districtName}`, { headers: authHeader() });
    }
    getFAreaIdExpressDestById(id){
        return axios.get(API_URL + `getFAreaIdExpressDestById/${id}`, { headers: authHeader() });
    }
    updateFAreaIdExpressDest(item){
        return axios.put(API_URL + `updateFAreaIdExpressDest/${item.id}`, item, {headers: authHeader()})
    }
    createFAreaIdExpressDest(item){
        return axios.post(API_URL + `createFAreaIdExpressDest`, item, {headers: authHeader()})
    }
    createFAreaIdExpressDestMultiple(items){
        return axios.post(API_URL + `createFAreaIdExpressDestMultiple`, items, {headers: authHeader()})
    }

    deleteFAreaIdExpressDest(id){
        return axios.delete(API_URL + `deleteFAreaIdExpressDest/${id}`, {
            headers: authHeader()
        });
    }

    deleteAllFAreaIdExpressDest(itemIds){
        return axios.delete(API_URL + `deleteAllFAreaIdExpressDest`, {
            headers:  authHeader(),
            data: itemIds
        });

    }

}
export default new FAreaIdExpressDestService()