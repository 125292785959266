
const ESchemaBasic1 = Object.freeze([
    {
        column: 'Nomor',
        type: Number,
        value: item => item.id
    },
    {
        column: 'kode1',
        type: String,
        value: item => item.kode1
    },
    {
        column: 'kode2',
        type: String,
        value: item => item.kode2
    },
    {
        column: 'description',
        type: String,
        value: item => item.description
    },
    {
        column: 'alias1',
        type: String,
        value: item => item.alias1
    },
    {
        column: 'alias2',
        type: String,
        value: item => item.alias2
    },
    {
        column: 'parentCode',
        type: String,
        value: item => item.parentCode
    },
    {
        column: 'parentName',
        type: String,
        value: item => item.parentName
    },


])


export default ESchemaBasic1