import axios from 'axios';
import authHeader from "../auth-header";
import ConstApiUrls from "../const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FAreaJntService {
    getApiUrl() {
        return API_URL
    }

    getAllFAreaJnt(){
        return axios.get(API_URL + `getAllFAreaJnt`, { headers: authHeader() });
    }
    getAllFAreaJntDestContaining(page, pageSize, sortBy, order, stateName, cityName, districtName, receiverArea){
        return axios.get(API_URL + `getAllFAreaJntDestContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&stateName=${stateName}&cityName=${cityName}&districtName=${districtName}&receiverArea=${receiverArea}`, { headers: authHeader() });
    }

    /**
     * HARUS PAKAI AND
     */
    getAllFAreaJntKecamatan(page, pageSize, sortBy, order, namaKec, namaKab, namaProv){
        // console.log(`${namaKec} >> ${namaKab} >> ${namaProv}`)

        return axios.get(API_URL + `getAllFAreaJntKecamatan?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&namaKec=${namaKec}&namaKab=${namaKab}&namaProv=${namaProv}`, { headers: authHeader() });
    }

    getAllFAreaJntSiteCode(page, pageSize, sortBy, order, sendSiteCode, destinationCode, receiverArea){
        return axios.get(API_URL + `getAllFAreaJntKecamatan?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&sendSiteCode=${sendSiteCode}&destinationCode=${destinationCode}&receiverArea=${receiverArea}`, { headers: authHeader() });
    }
    getFAreaJntById(id){
        return axios.get(API_URL + `getFAreaJntById/${id}`, { headers: authHeader() });
    }
    updateFAreaJnt(item){
        return axios.put(API_URL + `updateFAreaJnt/${item.id}`, item, {headers: authHeader()})
    }
    createFAreaJnt(item){
        return axios.post(API_URL + `createFAreaJnt`, item, {headers: authHeader()})
    }
    deleteFAreaJnt(id){
        return axios.delete(API_URL + `deleteFAreaJnt/${id}`, {
            headers: authHeader()
        });
    }

}
export default new FAreaJntService()