var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hint":"Kode, Deskripsi (press ENTER to search)","label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOnEnter.apply(null, arguments)},"blur":_vm.searchOnEnter}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"single-select":!_vm.multiSelect,"show-select":_vm.multiSelect,"headers":_vm.headers,"items":_vm.fSubAreasFiltered,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalTablePages =_vm.totalPaginationPages}},scopedSlots:_vm._u([(false)?{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 mr-4",attrs:{"align":"center"}},[_c('v-switch',{staticClass:"pa-3",attrs:{"label":_vm.multiSelect?'Multi Select':'Single Select',"x-small":""},model:{value:(_vm.multiSelect),callback:function ($$v) {_vm.multiSelect=$$v},expression:"multiSelect"}}),_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","dark":"","color":"red accent-4","x-small":"","disabled":!_vm.multiSelect},on:{"click":_vm.deleteDialogMultiShow}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-spacer'),_c('v-menu',{staticStyle:{"align-items":"start"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-grid")])],1)]}}],null,false,3080558192)},[_c('v-list',{staticClass:"mr",attrs:{"color":"grey lighten-4"}},[_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","elevation":"0","color":"gray darken-1","small":""},on:{"click":_vm.exportToExcel}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-excel")]),_vm._v(" Export ke Excel ")],1)],1)],1)],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","dark":"","color":"green","x-small":"","disabled":_vm.multiSelect},on:{"click":_vm.showDialogNew}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true}:null,{key:"item.statusActive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.alias1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.alias1)+" ")]),_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.alias2)+" ")])]}},{key:"item.fareaBean",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.lookupFArea(item.fareaBean))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.multiSelect},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.multiSelect},on:{"click":function($event){return _vm.deleteDialogShow(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red accent-4"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-container',[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per page"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"10","md":"9","sm":"8","align":"right"}},[_c('v-pagination',{attrs:{"length":_vm.totalPaginationPages,"total-visible":"8","circle":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('DeleteConfirmDialog',{ref:"refDeleteConfirmDialog",on:{"eventFromDeleteConfirmDialog1":_vm.deleteItemConfirmedSingleSelect,"eventFromDeleteConfirmDialog2":_vm.deleteItemConfirmedMultiSelect}}),_c('FSubAreaDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode},on:{"update:formMode":function($event){_vm.formMode=$event},"update:form-mode":function($event){_vm.formMode=$event},"eventFromFormDialogNew":_vm.saveDataNew,"eventFromFormDialogEdit":_vm.saveDataEdit}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackBarMesage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }